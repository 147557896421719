var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{staticStyle:{"height":"45px","width":"37px"},attrs:{"src":_vm.logo}}),_c('div',[_c('h2',{staticClass:"brand-text text-primary ml-1",staticStyle:{"color":"rgb(77, 20, 140)"}},[_vm._v(" purpletrac "),_c('p',{staticStyle:{"font-size":"8px","letter-spacing":"0.9px","line-height":"10px"}},[_vm._v("TRACKING BUSINESS METRICS")])])])]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" Register as a user ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"name","label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","name":"register-name","state":errors.length > 0 ? false:null,"placeholder":"john doe"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password2","label":"Password Confirm"}},[_c('validation-provider',{attrs:{"name":"Password Confirm","vid":"password2","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password2","type":_vm.passwordFieldType2,"state":errors.length > 0 ? false:null,"name":"register-password2","placeholder":"············"},model:{value:(_vm.password2),callback:function ($$v) {_vm.password2=$$v},expression:"password2"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon2},on:{"click":_vm.togglePasswordVisibility2}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" Register ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already reset password?")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" Sign in instead")])])],1)],1)],1),_c('b-col',{staticStyle:{"padding":"0px"},attrs:{"lg":"8"}},[_c('div',{staticClass:"login-image",staticStyle:{"height":"100%","display":"flex","justify-content":"center","align-items":"center"}},[_c('img',{staticStyle:{"padding":"150px 200px"},attrs:{"src":_vm.verticalLogo}})])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }